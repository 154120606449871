  <template>
    <div class="row">
      <!-- Kartu untuk setiap elemen dalam data_TypeTest -->
      <div v-for="(item, index) in data_TypeTest" :key="index" class="col-md-4">
        <div class="card card-custom bgi-no-repeat gutter-b card-stretch " style="background-position: right top; background-size: 30% auto; background-image: url(media/svg/shapes/abstract-5.svg)">
          <!--begin::Body-->
          <router-link :to="goToLink(item)">
            <div class="card-body">
              <a href="#" class="card-title font-weight-bold text-muted text-hover-primary font-size-h5">{{ item.TypeTestName }}</a>
              <div class="font-weight-bold text-success mt-9 mb-5"> Total {{ item.Total }} Question</div>
              <p class="text-dark-75 font-weight-bolder font-size-h5 m-0">
                {{ item.TypeTestDes }} - {{getNameCombo(item.TypeOfTypeTest)}}
              </p>
            </div>
          </router-link>
          <div class="d-flex justify-content-between align-items-center">
            <span class="text"> </span>
                <span class="navi-icon">
                  <b-dropdown
                  size="sm"
                  variant="link"
                  toggle-class="custom-v-dropdown"
                  no-caret
                  dropup text="Drop-Up"
                  right
                  no-flip
                  v-b-tooltip.hover="'Update'"
                  ref="myDropdown"
                >
                  <template v-slot:button-content>
                    <a href="#" class="btn btn-icon" data-toggle="dropdown">
                      <i @click="updateItem(index)" class="card-title font-weight-bold text-muted text-hover-danger flaticon2 flaticon2-pen">&nbsp; </i>

                    </a>
                  </template>
                  <!-- Navigation -->
                  <div class="navi navi-hover min-w-md-650px">
                    <b-dropdown-text tag="div" class="navi-header font-weight-bold">
                      Upadte Data Question Type:
                    </b-dropdown-text>
                    <b-dropdown-text tag="div" class="navi-separator mb-3"></b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-item">
                      <a href="#" class="navi-link">
                        <span class="navi-icon">
                          <i class="flaticon2-folder"></i>
                        </span>
                        <b-form-input
                          size="sm"
                          v-model="TypeTestNameUpdate"
                        />
                      </a>
                    </b-dropdown-text>
                    <b-dropdown-text tag="div" class="navi-item">
                      <a href="#" class="navi-link">
                        <span class="navi-icon">
                          <i class="flaticon2-clip-symbol"></i>
                        </span>
                        <b-form-input
                          size="sm"
                          v-model="TypeTestDesUpdate"
                        />
                      </a>
                    </b-dropdown-text>
                    <b-dropdown-text tag="div" class="navi-item">
                      <a  class="navi-link">
                        <span class="navi-icon">
                          <i class="flaticon2-list"></i>
                        </span>
                        <b-form-select size="sm" v-model="TypeOfTypeTestUpdate" :options="options"></b-form-select>
                      </a>
                    </b-dropdown-text>

                      <b-dropdown-text tag="div" class="navi-footer">
                        <a
                        class="btn btn-light-primary font-weight-bolder btn-sm"  
                        @click="updateData"
                          >Update</a>
                      </b-dropdown-text>
                  </div>
                  <!-- End Navigation -->
                </b-dropdown>
                <b-dropdown
                  size="sm"
                  variant="link"
                  toggle-class="custom-v-dropdown"
                  no-caret
                  dropup text="Drop-Up"
                  right
                  no-flip
                  v-b-tooltip.hover="'Delete'"
                >
                  <template v-slot:button-content>
                    <a href="#" class="btn btn-icon" data-toggle="dropdown">
                      <i class="card-title font-weight-bold text-muted text-hover-danger flaticon2 flaticon2-trash">&nbsp; </i>
                    </a>
                  </template>
                  <!-- Navigation -->
                  <div class="navi navi-hover min-w-md-400px">
                    <b-dropdown-text tag="div" class="navi-header font-weight-bold">
                      Delete Data Question Type:
                    </b-dropdown-text>
                    <b-dropdown-text tag="div" class="navi-separator mb-3"></b-dropdown-text>

                    <b-dropdown-text tag="div" class="navi-item">
                      <a href="#" class="navi-link">
                        <span class="navi-icon">
                          <i class="flaticon2-warning"></i>
                        </span>
                        Are you sure you want to delete it?<br>
                      </a>
                     
                        <h2>{{ item.TypeTestName }}</h2>
                    </b-dropdown-text>

                      <b-dropdown-text tag="div" class="navi-footer">
                        <a
                        class="btn btn-light-primary font-weight-bolder btn-sm"  @click="deleteItem(item.TypeTestID)"
                          >Delete</a
                        >
                      </b-dropdown-text>
                  </div>
                  <!-- End Navigation -->
                </b-dropdown>
                
               </span>
          </div>

        </div>
      </div>
    </div>
  </template>

<script>
import { Fetch_MTypeTest } from "@/core/services/store/mTypeTest.module";
import Services from "@/core/services/psikologi-api/Services";
import ApiService from "@/core/services/api.service";
import Swal from 'sweetalert2'
import EventBus from '@/EventBus';

export default {
  name: "WidgetType",
  data() {
    return {
      data_TypeTest: [],
      TypeTestNameUpdate: '',
      TypeTestDesUpdate: '',
      TypeOfTypeTestUpdate: null,
      data_TypeTestUpdate: [],
      showActions: false,
      IdType: '',
      options: [
        { value: 'CFIT', text: 'CFIT' },
        { value: 'MODALITAS_BELAJAR', text: 'Modalitas Belajar' },
        { value: 'MINAT_BAKAT', text: 'Minat Bakat' },
        { value: 'PAPI_KOSTICK', text: 'Papi Kostick' },
      ],
    };
  },

  methods: {
    getNameCombo(id){
        var result = ''
        var resObj = this.options.find(item => item.value === id);
        if(resObj != undefined)
          result = resObj.text

        return result
    },
    async getMasterTypeTest() {
      this.data_TypeTest = this.$store.state.mTypeTest.master_TypeTest;
    },

async loadData() {
  try {
    await this.$store.dispatch(Fetch_MTypeTest);
    this.getMasterTypeTest();
  } catch (error) {
    console.error('Failed to fetch data:', error);
    // Handle error, show a notification, etc.
  }
},
    async updateItem(index) {
            const updatedTypeTestId = this.data_TypeTest[index].TypeTestID;
            const updatedTypeTest = this.data_TypeTest.find(item => item.TypeTestID === updatedTypeTestId);
            this.TypeTestNameUpdate = updatedTypeTest.TypeTestName;
            this.TypeTestDesUpdate = updatedTypeTest.TypeTestDes;
            this.TypeOfTypeTestUpdate = updatedTypeTest.TypeOfTypeTest
            this.IdType = updatedTypeTest.TypeTestID;
          },

    updateData() {
        const formData = new FormData();
        formData.append("TypeTestName", this.TypeTestNameUpdate);
        formData.append("TypeTestDes", this.TypeTestDesUpdate);
        formData.append("TypeOfTypeTest", this.TypeOfTypeTestUpdate);

        let contentType = `application/form-data`;

        Services.PutData(
          ApiService,
          `master/typetests/${this.IdType}`,
          formData,
          contentType,
          response => {
            if (response.status) {
              Swal.fire({
                title: "",
                text: "Data Update successfully.",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });

              this.TypeTestNameUpdate = '';
              this.TypeTestDesUpdate = '';
              this.TypeOfTypeTestUpdate = null
              this.IdType = '';
              this.loadData();
            } else {
              Swal.fire({
                title: "",
                text: response.error || 'Update failed',
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
          },
        )
        .finally(() => {
          // Hide the dropdown regardless of success or failure
          this.$nextTick(() => {
            if (this.$refs.myDropdown) {
              this.$refs.myDropdown.show = false;
            }
          });
        });
      },

      goToLink(item){
        var result = ''
        if(item.TypeOfTypeTest == "MODALITAS_BELAJAR"){
           result = `/questions/modalitasbelajar/${item.TypeTestID}`
        }else if(item.TypeOfTypeTest == "MINAT_BAKAT"){
          result =  `/questions/minatbakat/${item.TypeTestID}`
        }else if(item.TypeOfTypeTest == "PAPI_KOSTICK"){
          result =  `/questions/papikostick/${item.TypeTestID}`
        }else{
          result =  `/questions/${item.TypeTestID}`
        }

        return result
      },

      async deleteItem(index) {
      this.IdType = index;
      let contentType = `application/form-data`;

      Services.DeleteData(
        ApiService,
        `master/typetests/${this.IdType}`,
        contentType,
        response => {
          if (response.status) {
            Swal.fire({
              title: "",
              text: "Data Delete successfully.",
              icon: "success",
              heightAuto: true,
              timer: 1500
            });
            this.IdType = '';
            this.loadData();
          } else {
            Swal.fire({
              title: "",
              text: response.error || 'Delete failed',
              icon: "info",
              heightAuto: true,
              timer: 1500
            });
          }
        },
      )
      .finally(() => {
        // Hide the dropdown regardless of success or failure
        this.$nextTick(() => {
          if (this.$refs.myDropdown) {
            this.$refs.myDropdown.show = false;
          }
        });
      });
    },


  },

  created() {
    EventBus.$on('data-saved', this.loadData);
  },
  destroyed() {
    EventBus.$off('data-saved', this.loadData);
  },

  mounted() {
    this.loadData();
  },
};
</script>
